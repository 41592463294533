export const LOAD_GAMES_REQUEST = "LOAD_GAMES_REQUEST"
export const LOAD_GAMES_SUCCESS = "LOAD_GAMES_SUCCESS"
export const LOAD_GAMES_FAILURE = "LOAD_GAMES_FAILURE"

export const LOAD_LATESTQUICKDRAW_REQUEST = "LOAD_LATESTQUICKDRAW_REQUEST"
export const LOAD_LATESTQUICKDRAW_SUCCESS = "LOAD_LATESTQUICKDRAW_SUCCESS"
export const LOAD_LATESTQUICKDRAW_FAILURE = "LOAD_LATESTQUICKDRAW_FAILURE"

export const LOAD_CATEGORIES_REQUEST = "LOAD_CATEGORIES_REQUEST"
export const LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS"
export const LOAD_CATEGORIES_FAILURE = "LOAD_CATEGORIES_FAILURE"

export const LOAD_TOPIC_REQUEST = "LOAD_TOPIC_REQUEST"
export const LOAD_TOPIC_SUCCESS = "LOAD_TOPIC_SUCCESS"
export const LOAD_TOPIC_FAILURE = "LOAD_TOPIC_FAILURE"

export const SET_TOPIC_SUCCESS = "SET_TOPIC_SUCCESS"

export const PROMOTIONS_REQUEST = "PROMOTIONS_REQUEST"
export const PROMOTIONS_SUCCESS = "PROMOTIONS_SUCCESS"
export const PROMOTIONS_FAILURE = "PROMOTIONS_FAILURE"

export const EVENTS_REQUEST = "EVENTS_REQUEST"
export const EVENTS_SUCCESS = "EVENTS_SUCCESS"
export const EVENTS_FAILURE = "EVENTS_FAILURE"

export const SCRATCH_OFF_GAMES_REQUEST = "SCRATCH_OFF_GAMES_REQUEST"
export const SCRATCH_OFF_GAMES_SUCCESS = "SCRATCH_OFF_GAMES_SUCCESS"
export const SCRATCH_OFF_GAMES_FAILURE = "SCRATCH_OFF_GAMES_FAILURE"

export const SCRATCH_OFF_GAME_REQUEST = "SCRATCH_OFF_GAME_REQUEST"
export const SCRATCH_OFF_GAME_SUCCESS = "SCRATCH_OFF_GAME_SUCCESS"
export const SCRATCH_OFF_GAME_FAILURE = "SCRATCH_OFF_GAME_FAILURE"

export const SCRATCH_OFF_GAMES_REPORT_REQUEST =
  "SCRATCH_OFF_GAMES_REPORT_REQUEST"
export const SCRATCH_OFF_GAMES_REPORT_SUCCESS =
  "SCRATCH_OFF_GAMES_REPORT_SUCCESS"
export const SCRATCH_OFF_GAMES_REPORT_FAILURE =
  "SCRATCH_OFF_GAMES_REPORT_FAILURE"

export const MARQUEE_REQUEST = "MARQUEE_REQUEST"
export const MARQUEE_SUCCESS = "MARQUEE_SUCCESS"
export const MARQUEE_FAILURE = "MARQUEE_FAILURE"

export const SCRATCH_OFF_GAMES_MARQUEE_REQUEST = "SCRATCH_OFF_GAMES_MARQUEE_REQUEST"
export const SCRATCH_OFF_GAMES_MARQUEE_SUCCESS = "SCRATCH_OFF_GAMES_MARQUEE_SUCCESS"
export const SCRATCH_OFF_GAMES_MARQUEE_FAILURE = "SCRATCH_OFF_GAMES_MARQUEE_FAILURE"

export const WINNERS_REQUEST = "WINNERS_REQUEST"
export const WINNERS_SUCCESS = "WINNERS_SUCCESS"
export const WINNERS_FAILURE = "WINNERS_FAILURE"

export const RECENT_WINNERS_REQUEST = "RECENT_WINNERS_REQUEST"
export const RECENT_WINNERS_SUCCESS = "RECENT_WINNERS_SUCCESS"
export const RECENT_WINNERS_FAILURE = "RECENT_WINNERS_FAILURE"

export const FEATURED_WINNERS_REQUEST = "FEATURED_WINNERS_REQUEST"
export const FEATURED_WINNERS_SUCCESS = "FEATURED_WINNERS_SUCCESS"
export const FEATURED_WINNERS_FAILURE = "RECENT_WINNERS_FAILURE"

export const WINNER_REQUEST = "WINNER_REQUEST"
export const WINNER_SUCCESS = "WINNER_SUCCESS"
export const WINNER_FAILURE = "WINNER_FAILURE"

export const SECOND_CHANCE_REQUEST = "SECOND_CHANCE_REQUEST"
export const SECOND_CHANCE_SUCCESS = "SECOND_CHANCE_SUCCESS"
export const SECOND_CHANCE_FAILURE = "SECOND_CHANCE_FAILURE"

export const TICKET_SCAN_CONTENT_REQUEST = 'TICKET_SCAN_CONTENT_REQUEST'
export const TICKET_SCAN_CONTENT_SUCCESS = 'TICKET_SCAN_CONTENT_SUCCESS'
export const TICKET_SCAN_CONTENT_FAILURE = 'TICKET_SCAN_CONTENT_FAILURE'

export const TICKET_SCAN_SCAN_RESULTS_REQUEST = 'TICKET_SCAN_SCAN_RESULTS_REQUEST'
export const TICKET_SCAN_SCAN_RESULTS_SUCCESS = 'TICKET_SCAN_SCAN_RESULTS_SUCCESS'
export const TICKET_SCAN_SCAN_RESULTS_FAILURE = 'TICKET_SCAN_SCAN_RESULTS_FAILURE'

export const TICKET_SCAN_FUEL_GAUGE_REQUEST = 'TICKET_SCAN_FUEL_GAUGE_REQUEST'
export const TICKET_SCAN_FUEL_GAUGE_SUCCESS = 'TICKET_SCAN_FUEL_GAUGE_SUCCESS'
export const TICKET_SCAN_FUEL_GAUGE_FAILURE = 'TICKET_SCAN_FUEL_GAUGE_FAILURE'

export const TOGGLE_RESULTS_LOADING = 'TOGGLE_RESULTS_LOADING'
export const RESET_RESULTS_TEMPLATE = 'RESET_RESULTS_TEMPLATE'

export const TICKET_SCAN_SCAN_RESULTS_CMS_REQUEST = 'TICKET_SCAN_SCAN_RESULTS_CMS_REQUEST'
export const TICKET_SCAN_SCAN_RESULTS_CMS_SUCCESS = 'TICKET_SCAN_SCAN_RESULTS_CMS_SUCCESS'
export const TICKET_SCAN_SCAN_RESULTS_CMS_FAILURE = 'TICKET_SCAN_SCAN_RESULTS_CMS_FAILURE'

export const TICKET_SCAN_LP_CMS_REQUEST = 'TICKET_SCAN_LP_CMS_REQUEST'
export const TICKET_SCAN_LP_CMS_SUCCESS = 'TICKET_SCAN_LP_CMS_SUCCESS'
export const TICKET_SCAN_LP_CMS_FAILURE = 'TICKET_SCAN_LP_CMS_FAILURE'
export const LOAD_CMS_GAMES_REQUEST = "LOAD_CMS_GAMES_REQUEST"
export const LOAD_CMS_GAMES_SUCCESS = "LOAD_CMS_GAMES_SUCCESS"
export const LOAD_CMS_GAMES_FAILURE = "LOAD_CMS_GAMES_FAILURE"

export const PRIZE_CLAIM_REQUEST = "PRIZE_CLAIM_REQUEST"
export const PRIZE_CLAIM_SUCCESS = "PRIZE_CLAIM_SUCCESS"
export const PRIZE_CLAIM_FAILURE = "PRIZE_CLAIM_FAILURE"

export const BASIC_PAGE_IDS_REQUEST = "BASIC_PAGE_IDS_REQUEST"
export const BASIC_PAGE_IDS_SUCCESS = "BASIC_PAGE_IDS_SUCCESS"
export const BASIC_PAGE_IDS_FAILURE = "BASIC_PAGE_IDS_FAILURE"

export const PAGE_CONTENT_REQUEST = "PAGE_CONTENT_REQUEST"
export const PAGE_CONTENT_SUCCESS = "PAGE_CONTENT_SUCCESS"
export const PAGE_CONTENT_FAILURE = "PAGE_CONTENT_FAILURE"

export const FOOTER_LINKS_REQUEST = "FOOTER_LINKS_REQUEST"
export const FOOTER_LINKS_SUCCESS = "FOOTER_LINKS_SUCCESS"
export const FOOTER_LINKS_FAILURE = "FOOTER_LINKS_FAILURE"

export const FOOTER_COPY_REQUEST = "FOOTER_COPY_REQUEST"
export const FOOTER_COPY_SUCCESS = "FOOTER_COPY_SUCCESS"
export const FOOTER_COPY_FAILURE = "FOOTER_COPY_FAILURE"

export const FOOTER_SOCIALS_REQUEST = "FOOTER_SOCIALS_REQUEST"
export const FOOTER_SOCIALS_SUCCESS = "FOOTER_SOCIALS_SUCCESS"
export const FOOTER_SOCIALS_FAILURE = "FOOTER_SOCIALS_FAILURE"

export const STICKY_FOOTERS_REQUEST = "STICKY_FOOTERS_REQUEST"
export const STICKY_FOOTERS_SUCCESS = "STICKY_FOOTERS_REQUEST"
export const STICKY_FOOTERS_FAILURE = "STICKY_FOOTERS_FAILURE"

export const EMAIL_SUBSCRIBE_REQUEST = "EMAIL_SUBSCRIBE_REQUEST"
export const TPOIC_UPDATE_REQUEST = "TPOIC_UPDATE_REQUEST"
export const EMAIL_SUBSCRIBE_SUCCESS = "EMAIL_SUBSCRIBE_SUCCESS"
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS"
export const FETCH_QUESTION_FAILURE = "FETCH_QUESTION_FAILURE"
export const EMAIL_SUBSCRIBE_FAILURE = "EMAIL_SUBSCRIBE_FAILURE"
export const FETCH_UPDATE_TOPIC_SUCCESS = "FETCH_UPDATE_TOPIC_SUCCESS"
export const FETCH_UPDATE_TOPIC_FAILURE = "FETCH_UPDATE_TOPIC_FAILURE"

export const BASIC_PAGE_REQUEST = "BASIC_PAGE_REQUEST"
export const BASIC_PAGE_SUCCESS = "BASIC_PAGE_SUCCESS"
export const BASIC_PAGE_FAILURE = "BASIC_PAGE_FAILURE"

export const WHERE_TO_WATCH_REQUEST = "WHERE_TO_WATCH_REQUEST"
export const WHERE_TO_WATCH_SUCCESS = "WHERE_TO_WATCH_SUCCESS"
export const WHERE_TO_WATCH_FAILURE = "WHERE_TO_WATCH_FAILURE"

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST"
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS"
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE"

export const PAGE_DETAIL_CONTENT_REQUEST = "PAGE_DETAIL_CONTENT_REQUEST"
export const PAGE_DETAIL_CONTENT_SUCCESS = "PAGE_DETAIL_CONTENT_SUCCESS"
export const PAGE_DETAIL_CONTENT_FAILURE = "PAGE_DETAIL_CONTENT_FAILURE"

export const DYNAMICPAGE_CONTENT_REQUEST = "DYNAMICPAGE_CONTENT_REQUEST"
export const DYNAMICPAGE_CONTENT_SUCCESS = "DYNAMICPAGE_CONTENT_SUCCESS"
export const DYNAMICPAGE_CONTENT_FAILURE = "DYNAMICPAGE_CONTENT_FAILURE"

export const COUNTY_BREAKDOWN_REQUEST = "COUNTY_BREAKDOWN_REQUEST"
export const COUNTY_BREAKDOWN_SUCCESS = "COUNTY_BREAKDOWN_SUCCESS"
export const COUNTY_BREAKDOWN_FAILURE = "COUNTY_BREAKDOWN_FAILURE"

export const REGIONS_REQUEST = "REGIONS_REQUEST"
export const REGIONS_SUCCESS = "REGIONS_SUCCESS"
export const REGIONS_FAILURE = "REGIONS_FAILURE"

export const PRIZE_CLAIM_CENTER_REQUEST = "PRIZE_CLAIM_CENTER_REQUEST"
export const PRIZE_CLAIM_CENTER_SUCCESS = "PRIZE_CLAIM_CENTER_SUCCESS"
export const PRIZE_CLAIM_CENTER_FAILURE = "PRIZE_CLAIM_CENTER_FAILURE"

export const WINNING_NUMBERS_REQUEST = "WINNING_NUMBERS_REQUEST"
export const WINNING_NUMBERS_SUCCESS = "WINNING_NUMBERS_SUCCESS"
export const WINNING_NUMBERS_FAILURE = "WINNING_NUMBERS_FAILURE"

export const LOAD_SERVICE_CENTER_REQUEST = "LOAD_SERVICE_CENTER_REQUEST"
export const LOAD_SERVICE_CENTER_SUCCESS = "LOAD_SERVICE_CENTER_SUCCESS"
export const LOAD_SERVICE_CENTER_FAILURE = "LOAD_SERVICE_CENTER_FAILURE"

export const ADMIN_ALERT_REQUEST = "ADMIN_ALERT_REQUEST"
export const ADMIN_ALERT_SUCCESS = "ADMIN_ALERT_SUCCESS"
export const ADMIN_ALERT_FAILURE = "ADMIN_ALERT_FAILURE"
export const ADMIN_ALERT_TOGGLE = "ADMIN_ALERT_TOGGLE"

export const LOAD_EMERGENCY_ALERT_REQUEST = "LOAD_EMERGENCY_ALERT_REQUEST"
export const LOAD_EMERGENCY_ALERT_SUCCESS = "LOAD_EMERGENCY_ALERT_SUCCESS"
export const LOAD_EMERGENCY_ALERT_FAILURE = "LOAD_EMERGENCY_ALERT_FAILURE"

export const LOAD_TOKEN_REQUEST = "LOAD_TOKEN_REQUEST"
export const LOAD_TOKEN_SUCCESS = "LOAD_TOKEN_SUCCESS"
export const LOAD_TOKEN_FAILURE = "LOAD_TOKEN_FAILURE"

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST"
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE"

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE"

export const SWEEPS_REQUEST = "SWEEPS_REQUEST"
export const SWEEPS_SUCCESS = "SWEEPS_SUCCESS"
export const SWEEPS_FAILURE = "SWEEPS_FAILURE"

export const SET_REDIRECT = "SET_REDIRECT"

export const LOAD_LIVE_SHOW_DATA_REQUEST = "LOAD_LIVE_SHOW_DATA_REQUEST"
export const LOAD_LIVE_SHOW_DATA_SUCCESS = "LOAD_LIVE_SHOW_DATA_SUCCESS"
export const LOAD_LIVE_SHOW_DATA_FAILURE = "LOAD_LIVE_SHOW_DATA_FAILURE"

export const LOAD_LIVE_SHOW_DATA_RANGE_REQUEST =
  "LOAD_LIVE_SHOW_DATA_RANGE_REQUEST"
export const LOAD_LIVE_SHOW_DATA_RANGE_SUCCESS =
  "LOAD_LIVE_SHOW_DATA_RANGE_SUCCESS"
export const LOAD_LIVE_SHOW_DATA_RANGE_FAILURE =
  "LOAD_LIVE_SHOW_DATA_RANGE_FAILURE"

export const LOAD_NEXT_LIVE_DRAW_REQUEST = "LOAD_NEXT_LIVE_DRAW_REQUEST"
export const LOAD_NEXT_LIVE_DRAW_SUCCESS = "LOAD_NEXT_LIVE_DRAW_SUCCESS"
export const LOAD_NEXT_LIVE_DRAW_FAILURE = "LOAD_NEXT_LIVE_DRAW_FAILURE"

export const SEARCH_DRAW_REQUEST = "SEARCH_DRAW_REQUEST"
export const SEARCH_DRAW_SUCCESS = "SEARCH_DRAW_SUCCESS"
export const SEARCH_DRAW_FAILURE = "SEARCH_DRAW_FAILURE"
export const RESET_SEARCH_NUMBER = "RESET_SEARCH_NUMBER"

export const RESET_LIVE_SHOW_STATE = "RESET_LIVE_SHOW_STATE"
export const SET_ACTIVE_DRAW_NUMBER = "SET_ACTIVE_DRAW_NUMBER"
export const GO_TO_LIVE_START = "GO_TO_LIVE_START"
export const GO_TO_LIVE_END = "GO_TO_LIVE_END"

export const STRINGS_REQUEST = "STRINGS_REQUEST"
export const STRINGS_SUCCESS = "STRINGS_SUCCESS"
export const STRINGS_FAILURE = "STRINGS_FAILURE"

export const CONTACT_FORM_REQUEST = "CONTACT_FORM_REQUEST"
export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS"
export const CONTACT_FORM_ERROR = "CONTACT_FORM_ERROR"

export const LOADING = "LOADING"
export const LOADED = "LOADED"
export const LOADING_FAILURE = "LOADING_FAILURE"
export const LOADING_RESET = "LOADING_RESET"

export const LOAD_TICKERS_REQUEST = "LOAD_TICKERS_REQUEST";
export const LOAD_TICKERS_SUCCESS = "LOAD_TICKERS_SUCCESS";
export const LOAD_TICKERS_FAILURE = "LOAD_TICKERS_FAILURE";

export const INTEGRITY_REQUEST = "INTEGRITY_REQUEST";
export const INTEGRITY_SUCCESS = "INTEGRITY_SUCCESS";
export const INTEGRITY_FAILURE = "INTEGRITY_FAILURE";

export const SET_APP_VIEW_SUCCESS = "SET_APP_VIEW_SUCCESS"
